




































import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
  computed,
  useContext,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { SfBanner, SfBannerGrid } from '@storefront-ui/vue';
import { CmsPage } from '~/modules/GraphQL/types';
import HeroSection from '~/components/HeroSection.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent, useUiState } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import Promo from "~/components/FortyTwo/homepage/Promo.vue";
import Happening from "~/components/FortyTwo/homepage/Happening.vue";
import HeroBanner from "~/components/FortyTwo/homepage/HeroBanner.vue";
import Highlight from "~/components/FortyTwo/homepage/Highlight.vue";
import Beautiful from "~/components/FortyTwo/homepage/Beautiful.vue";
import HappeningWithEmpty from "~/components/FortyTwo/homepage/HappeningWithEmpty.vue";
import BeautifulWithEmpty from "~/components/FortyTwo/homepage/BeautifulWithEmpty.vue";
import ShopByStyleWithEmpty from "~/components/FortyTwo/homepage/ShopByStyleWithEmpty.vue";
import DYDataHandler from "~/components/FortyTwo/homepage/DYRecommendation/DYDataHandler.vue";
import BrandWithEmpty from "~/components/FortyTwo/homepage/BrandWithEmpty.vue";
import Brand from "~/components/FortyTwo/homepage/Brand.vue";
import ShopByStyle from "~/components/FortyTwo/homepage/ShopByStyle.vue";
import InstaHome from "~/components/FortyTwo/homepage/InstaHome.vue";
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";
import DYProductWithEmpty from "~/components/FortyTwo/homepage/DYRecommendation/DYProductWithEmpty.vue";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import CustomHead from '~/dy/customHead.vue';
import useFtSendFacebookPixel from '~/composables/FortyTwo/useFtSendFacebookPixel';
import SeeOurFurnitureInPerson from "~/components/FortyTwo/homepage/SeeOurFurnitureInPerson.vue";
import SeeOurFurnitureInPersonWithEmpty from "~/components/FortyTwo/homepage/SeeOurFurnitureInPersonWithEmpty.vue";
import { getItem,setItem } from "~/helpers/asyncLocalStorage";

export default defineComponent({
  name: 'FortytwoHomePage',
  components: {
    HeroSection,
    LazyHydrate,
    LoadWhenVisible,
    SfBanner,
    SfBannerGrid,
    CallToAction: () => import(/* webpackPrefetch: true */ '~/components/CallToAction.vue'),
    InstagramFeed: () => import(/* webpackPrefetch: true */ '~/components/InstagramFeed.vue'),
    MobileStoreBanner: () => import(/* webpackPrefetch: true */ '~/components/MobileStoreBanner.vue'),
    NewProducts: () => import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
    Promo,
    Happening,
    HeroBanner,
    Highlight,
    Beautiful,
    Brand,
    ShopByStyle,
    InstaHome,
    SkeletonLoader,
    BeautifulWithEmpty,
    HappeningWithEmpty,
    ShopByStyleWithEmpty,
    BrandWithEmpty,
    DYDataHandler,
    DYProductWithEmpty,
    CustomHead,
    SeeOurFurnitureInPerson,
    SeeOurFurnitureInPersonWithEmpty,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const { sendFacebookPixel } = useFtSendFacebookPixel();
    const dydata:any = ref("");
    const { addTags } = useCache();
    // const page = ref<CmsPage | null>(null);
    const heroBannerData = ref();
    const Beautifulforroom = ref();
    const HappeningNow = ref();
    const shopByStyle = ref();
    const shopByBranchs = ref();
    const seeOurFurnitureInPerson = ref();
    const DYProduct = ref();
    const selectType = ["HeroBanner","Beautifulforroom","[Home] Recommendations","Happening Now","Shop By Style","Shop By Brands","See Our Furniture In Person"];
    const CACHE_KEY = 'DY_DATA_CACHE';
    

    const fetchDYData = async () => {
      const currentURL = window.location.href;
      const cachedData:any = await getItem(CACHE_KEY);
      const CACHE_DURATION = 60 * 60 * 1000; //1 hour in ms
      if(cachedData){
        const { data, timestamp }  = JSON.parse(cachedData);
        const now = new Date().getTime();
        if (now - timestamp < CACHE_DURATION) {
          // Use cached data if it's still valid
          dydata.value = data;
          mapDYData(dydata.value);
          return;
        }
      }
      await apidycall(selectType,"HOMEPAGE","Choose",[],[],currentURL,false);
      dydata.value  = dyresultdata.value;

      mapDYData(dydata.value);
      const cacheObject = {
        data: dydata.value,
        timestamp: new Date().getTime()
      };
      await setItem(CACHE_KEY,JSON.stringify(cacheObject));
    };

    const mapDYData = (data) => {
      data.forEach(item => {
        switch(item.campaigntype) {
          case "HeroBanner":
            heroBannerData.value = { data: item.data, decisionId: item.decisionId };
            break;
          case "Beautifulforroom":
            Beautifulforroom.value = { data: item.data, decisionId: item.decisionId };
            break;
          case "[Home] Recommendations":
            DYProduct.value = { data: item.data, decisionId: item.decisionId, title: item.title, itemsku: item.itemsku, variationsId: item.variationsId };
            break;
          case "Happening Now":
            HappeningNow.value = { data: item.data, decisionId: item.decisionId };
            break;
          case "Shop By Style":
            shopByStyle.value = { data: item.data, decisionId: item.decisionId };
            break;
          case "Shop By Brands":
            shopByBranchs.value = { data: item.data, decisionId: item.decisionId };
            break;
          case "See Our Furniture In Person":
            seeOurFurnitureInPerson.value = { data: item.data, decisionId: item.decisionId };
            break;
        }
      });
    };
    
    useFetch(async () => {
      // page.value = await loadPage({ identifier: 'home' });  
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    onMounted( async () => {
      if(process.client){
        fetchDYData();
      }

      // facabook pixel and conversions api
      await sendFacebookPixel('PageView', '');
    });

    // @ts-ignore
    return {
      heroBannerData,
      Beautifulforroom,
      DYProduct,
      HappeningNow,
      shopByStyle,
      shopByBranchs,
      seeOurFurnitureInPerson,
      isDYProductready : computed(()=> DYProduct.value ? true : false),
      isShopByBrandready : computed(()=> shopByBranchs.value ? true : false),
      isShopByStyleready : computed(()=> shopByStyle.value ? true : false),
      isHappeningready : computed(()=> HappeningNow.value ? true : false),
      isbeautifulready : computed(()=> Beautifulforroom.value ? true : false),
      isherobannerready : computed(()=> heroBannerData.value ? true : false),
      isSeeOurFurnitureInPerson : computed(()=> seeOurFurnitureInPerson.value ? true : false),
    };
  },
  head() {
    const page = { title: 'Furnish Your Home', og_title: 'Furnish Your Home  | Furniture & Home Décor'}
    return getMetaInfo(page);
  },
});
